.advantages {
  width: 100%;
  margin-bottom: 80px;
}

.advantages-title {
  flex-wrap: nowrap;
  justify-content: space-between;
  gap: 24px;
  margin-bottom: 50px;
  display: flex;
}

.advantages-title span {
  max-width: 453px;
  font-size: 32px;
  font-weight: 700;
}

.advantages-title p {
  text-align: left;
  color: var(--basic-black);
  opacity: .7;
  max-width: 553px;
  font-size: 16px;
  font-weight: 600;
}

.advantages-grid {
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  display: grid;
}

.advantages-grid__item {
  background: #fff;
  border-radius: 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 26px;
  width: 100%;
  height: auto;
  padding: 32px;
  display: flex;
}

.advantages-grid__item-img {
  width: 50px;
}

.advantages-grid__item-name {
  text-align: center;
  color: var(--basic-black);
  font-size: 20px;
  font-weight: 700;
}

.advantages-title__black {
  color: var(--basic-black);
}

.advantages-title__brand {
  color: var(--basic-brand);
}

@media (width <= 1100px) {
  .advantages-grid__item-name {
    font-size: 18px;
  }
}

@media (width <= 901px) {
  .advantages-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (width <= 801px) {
  .advantages-title {
    flex-direction: column;
    gap: 16px;
  }

  .advantages-title p {
    margin: 0;
  }
}

@media (width <= 601px) {
  .advantages {
    margin-bottom: 64px;
  }

  .advantages-grid {
    grid-template-columns: repeat(1, 1fr);
  }

  .advantages-title {
    margin-bottom: 40px;
  }
}
/*# sourceMappingURL=index.b7d80d54.css.map */
