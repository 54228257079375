.advantages {
    width: 100%;
    margin-bottom: 80px;
}

.advantages-title {
    margin-bottom: 50px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    gap: 24px;
}

.advantages-title span {
    max-width: 453px;
    font-size: 32px;
    font-weight: 700;
}

.advantages-title p {
    max-width: 553px;
    text-align: left;
    font-size: 16px;
    font-weight: 600;
    color: var(--basic-black);
    opacity: 0.7;
}

.advantages-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
}

.advantages-grid__item {
    width: 100%;
    height: auto;
    padding: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 26px;
    background: #ffffff;
    border-radius: 40px;
}

.advantages-grid__item-img {
    width: 50px;
}

.advantages-grid__item-name {
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    color: var(--basic-black);
}

.advantages-title__black {
    color: var(--basic-black);
}

.advantages-title__brand {
    color: var(--basic-brand);
}

@media (max-width: 1100px) {
    .advantages-grid__item-name {
        font-size: 18px;
    }
}

@media (max-width: 901px) {
    .advantages-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 801px) {
    .advantages-title {
        flex-direction: column;
        gap: 16px;
    }

    .advantages-title p {
        margin: 0;
    }
}

@media (max-width: 601px) {
    .advantages {
        margin-bottom: 64px;
    }

    .advantages-grid {
        grid-template-columns: repeat(1, 1fr);
    }

    .advantages-title {
        margin-bottom: 40px;
    }
}
